:root {
  --blue-400: #cae1ff;
  --blue-300: #ebf3fd;
  --blue-700: #005cb8;
  --blue-800: #004ca7;
  --blue-900: #274582;
  --blue-950: #113159;

  --gray-200: #f6faff;
  --gray-300: #ebf1f8;
  --gray-400: #dce2e9;
  --gray-500: #cfd5db;
  --gray-700: #898e95;
  --gray-800: #54585e;
  --gray-900: #1c2025;

  --size-xs: 12px;
  --font-size-sm: 14;
  --size-md: 16px;
  --font-size-xl: 20px;

  --color-text-subtle: #54585e;
  --color-text-danger: #a10210;
  --color-text-subtlest: #898e95;
  --color-border-hover: #b7bcc3;
  --color-text-button-secondary-hover: var(--gray-900);
  --color-border-pressed: #b7bcc3;
  --color-text-button-secondary-pressed: var(--gray-900);
  --color-background-button-pressed: #f6faff;

  --color-border-button-secondary-hover: #B7BCC3;
  --color-text-button-secondary-hover: #1C2025;
  --color-border-button-secondary-pressed: #B7BCC3;
  --color-text-button-secondary-pressed: #1C2025;

  --color-border-control: #cfd5db;
  --color-placeholder-control: #898e95;
  --color-text-control: #1c2025;

  --color-background-sidebar-item-active: var(--blue-400);

  --yellow-300: #ffedd6;
  --color-text-accent-yellow: #ba6100;
  --green-300: #e5f7ea;
  --color-text-accent-green: #00823e;

  --color-text-brand: #005CB8;

  --color-icon-inverse: #FFFFFF;

  /* variaveis antigas */

  --brand-color-500: #136695;
  --brand-color-600: #0e64ae;
  --brand-color-700: #0b4e88;

  --gray-50: #f3f4f5;
  --gray-100: #dfe3e8;
  /* --gray-200: #ced3d8; */
  /* --gray-300: #b5bec5; */
  /* --gray-400: #9ca8b1; */
  /* --gray-500: #83929e; */
  --gray-600: #7f8387;
  /* --gray-700: #526677; */
  /* --gray-800: #395164; */
  /*  --gray-900: #545a6d; */
  --gray-950: #08253d;

  --slate-100: #ecf1f6;
  --slate-200: #e2e8f0;
  --slate-300: #cbd5e1;

  --sidebar-background: var(--white-000);
  --sidebar-item-selected: #11324f;
  --sidebar-category: 12px;

  --h1-semibold: 28px;
  --h2-semibold: 23px;
  --h3-semibold: 19px;
  --h4-semibold: 16px;
  --h5-medium: 14px;
  --h6-semibold: 28px;

  --text-large: 16px;
  --text-medium: 14px;
  --text-xsmall: 13px;
  --text-2xsmall: 12px;

  --orange-400: #da6955;
  --orange-500: #d1432a;
  --orange-600: #be3d26;
  --orange-700: #94301e;

  --support-01: #009e5b;
  --support-02: var(--orange-500);
  --support-03: #db950b;
  --support-04: #117edb;
  --support-05: #7939cc;

  --red-300: #fa8d8d;
  --red-400: #f35555;
  --red-500: #f44e4e;
  --red-600: #cd2424;
  --red-200: #ffc6c6;
  --red-100: #f8e7e9;

  /*   --blue-500: #2994e1; */
  /* --blue-800: #1d2532; */
  --blue-100: #eeeeee;
  --blue-200: #edf7ff;

  --dark-blue-500: #5255e1;
  --dark-blue-600: #393cb8;
  --dark-blue-900: #070841;

  --green-50: #e6f6ee;
  --green-100: #d9f2e6;
  /* --green-300: #00a859; */
  --green-400: #009750;
  --green-500: #00b669;
  --green-700: #009e5b;
  --green-800: #02857c;
  --green-900: #085f3c;

  --purple-600: #7f48b7;

  --primaria: #0c253b;

  --white-000: #ffffff;

  --box-shadow: rgba(0, 0, 0, 0.1);
}
