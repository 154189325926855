/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import "./app/shared/scss/main.scss";

@import "../node_modules/ngx-toastr/toastr.css";

@import "@ng-select/ng-select/themes/default.theme.css";

@import "./app/shared/componentes/editor-de-texto/editor-de-texto.component.scss";

* {
  box-sizing: border-box;
  font-family: "Hind" !important;
}

html,
body {
  height: 100%;
  background-color: var(--gray-300);
  margin: 0 !important;
}

html {
  overflow: hidden;
}

h3,
p {
  margin: 0 !important;
}

.bx {
  font-size: 25px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1em;

  .grid-1,
  .grid-2,
  .grid-3,
  .grid-4,
  .grid-5,
  .grid-6,
  .grid-7,
  .grid-8,
  .grid-9,
  .grid-10,
  .grid-11,
  .grid-12 {
    grid-column: span 12;
  }

  @media (min-width: 500px) {
    .grid-1 {
      grid-column: span 1;
    }

    .grid-2 {
      grid-column: span 2;
    }

    .grid-3 {
      grid-column: span 3;
    }

    .grid-4 {
      grid-column: span 4;
    }

    .grid-5 {
      grid-column: span 5;
    }

    .grid-6 {
      grid-column: span 6;
    }

    .grid-7 {
      grid-column: span 7;
    }

    .grid-8 {
      grid-column: span 8;
    }

    .grid-9 {
      grid-column: span 9;
    }

    .grid-10 {
      grid-column: span 10;
    }

    .grid-11 {
      grid-column: span 11;
    }

    .grid-12 {
      grid-column: span 12;
    }
  }

  .grid-row-2 {
    grid-row: span 2;
  }

  .grid-row-3 {
    grid-row: span 3;
  }

  .grid-row-24 {
    grid-row: span 24;
  }
}

.modal-content {
  border: none !important;
  border-radius: 8px;
}

.modal-backdrop {
  background-color: transparent !important;
}