.NgxEditor__Wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray-100);
  border-radius: 6px;
  overflow: hidden;

  .NgxEditor__MenuBar {
    border-bottom: 1px solid var(--gray-100) !important;
    color: var(--gray-800);
    min-height: 40px;
    display: flex;
    align-items: center;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;

    .NgxEditor__MenuItem {
      cursor: pointer;
    }

    .NgxEditor__Seperator {
      border-left: 1px solid var(--gray-100) !important;
    }
  }

  .NgxEditor {
    height: 100%;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    .ProseMirror {
      height: 100%;
    }

    .NgxEditor__Placeholder:before {
      color: var(--gray-500);
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.editor-desabilitado {
  border: 1px solid var(--gray-200);

  .NgxEditor__MenuBar {
    background-color: var(--gray-100);
    border-bottom: 1px solid var(--gray-200) !important;
  }

  .NgxEditor {
    background-color: var(--gray-100);

    .NgxEditor__Placeholder:before {
        color: var(--gray-600);
    }
  }
}
