
#toast-container {
  > div {
    box-shadow: var(--box-shadow);
    opacity: 1;
  }
  > :hover {
    box-shadow: var(--box-shadow);
    opacity: 0.9;
  }
}

@mixin toast-variant($bg) {
  background-color: #E9FDF1 !important;
  color: var(--green-800) !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  box-shadow: 0px 2px 4px 0px #0B1F4D14 !important;
  background-image: url('../../../../../assets/imagens/backgrounds/check-circle.svg') !important;
  background-size: auto !important;
}

@each $color, $value in $theme-colors {
  .toast-#{$color} {
    @include toast-variant($value);
  }
}

// for error

.toast-error {
  background-color: #FFEEEB;
  color: var(--orange-600) !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  box-shadow: 0px 2px 4px 0px #0B1F4D14 !important;
  background-image: url('../../../../../assets/imagens/backgrounds/x-circle.svg') !important;
  background-size: auto !important;
}
