.dropdown-menu {
  border-radius: 4px;
  min-width: 200px;
  padding: 0;
  border: none;
  box-shadow: 0px 0px 15px 0px #6b7c8b3b;
  overflow: hidden;

  li:active {
    .dropdown-item {
      background-color: var(--blue-100) !important;
    }
  }

  li {
    .dropdown-item {
      cursor: pointer;
      color: var(--gray-800);
      display: flex;
      align-items: center;
      column-gap: 0.3em;
      padding: 6px 13px;
      font-size: 14px;
      font-weight: 400;

      &:focus {
        outline: none;
      }

      i {
        font-size: 16px;
      }

      span {
        text-transform: capitalize;
      }
    }

    &:first-of-type {
      .dropdown-item {
        padding-top: 11px;
      }
    }

    &:last-of-type {
      .dropdown-item {
        padding-bottom: 11px;
      }
    }
  }

  .list-item-danger {
    border-top: 1px solid var(--slate-200);

    .dropdown-item {
      color: var(--support-02) !important;
    }
  }

  .list-item-navigate {
    .dropdown-item {
      position: relative;

      &::after {
        content: '\276F';
        position: absolute;
        right: 16px;
        font-size: 10px;
        font-weight: 600;
      }
    }
  }
}

.g2-btn-one {
  background-color: var(--primaria);
  border: none;
  border-radius: 6.63116px;
  color: var(--white-000);
  font-weight: 500;
  font-size: 0.9em;
  letter-spacing: 0.2px;
  cursor: pointer;

  &:hover {
    background-color: lighten(#002d4f, 2%);
  }

  &:active {
    background-color: lighten(#002d4f, 5%) !important;
  }
}

.g2-btn-two {
  background-color: var(--blue-700);
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3em;
  color: var(--white-000);
  min-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  font-weight: 500;
  font-size: var(--font-size-sm);

  i {
    font-size: 16px;
  }

  &:hover {
    background-color: var(--blue-800);
    opacity: 0.8;
  }

  &:active {
    background-color: var(--blue-950);
    opacity: 0.9;
  }

  &:disabled {
    background-color: var(--gray-500);
    pointer-events: none;
    user-select: none;
  }
}

.g2-btn-two-negativo {
  @extend .g2-btn-two;
  background-color: transparent;
  color: var(--gray-800);
  border: 1px solid var(--gray-500);

  &:hover {
    background-color: transparent;
    border-color: var(--color-border-hover);
    color: var(--color-text-button-secondary-hover);
  }

  &:active {
    background-color: var(--color-background-button-pressed);
    border-color: var(--color-border-pressed);
    color: var(--color-text-button-secondary-pressed);
  }
}

.g2-btn-two-possitivo {
  @extend .g2-btn-two;
  background-color: var(--green-700);
  color: var(--white-000);
  border: none;

  &:hover {
    background-color: var(--green-800);
  }

  &:active {
    background-color: var(--green-900);
  }
}

.g2-btn-unchecked,
.g2-btn-checked {
  background-color: var(--gray-300);
  color: var(--gray-700);
  box-sizing: border-box;
  min-height: 25px;
  min-width: 25px;
  margin: auto;
  border: none;
  display: block;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.g2-btn-checked {
  background-color: var(--green-500);
  color: var(--white-000);
  font-size: 13px;
  padding: 0;

  &:hover {
    background-color: darken(#00b669, 3%);
  }

  &:active {
    background-color: darken(#00b669, 5%) !important;
  }
}

.g2-btn-two-danger {
  @extend .g2-btn-two;
  background-color: var(--support-02) !important;

  &:hover {
    background-color: var(--orange-600) !important;
  }

  &:active {
    background-color: var(--orange-700) !important;
  }
}

.g2-btn-three-positivo,
.g2-btn-three-negativo {
  padding: 0.6em 1em;
  color: var(--white-000);
  font-weight: 400;
  border-radius: 3px;
  white-space: nowrap;
  border: none;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.g2-btn-three-negativo {
  background-color: var(--orange-500);

  &:hover {
    background-color: lighten(#e28809, 5%);
  }

  &:active {
    background-color: darken(#e28809, 4%) !important;
  }
}

.g2-btn-three-positivo {
  background-color: var(--green-500);

  &:hover {
    background-color: darken(#00b669, 3%);
  }

  &:active {
    background-color: darken(#00b669, 5%) !important;
  }
}

.g2-btn-rounded {
  background-color: var(--brand-color-500);
  border: none;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3em;
  color: var(--white-000);
  min-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  font-weight: 600;

  i {
    font-size: 1.2em;
  }

  span {
    font-size: var(--text-medium);
  }

  &:hover {
    background-color: var(--brand-color-600);
  }

  &:active {
    background-color: var(--brand-color-700);
  }
}

.g2-btn-outline {
  @extend .g2-btn-two;
  background-color: transparent;
  border: solid 1px var(--gray-500);
  color: var(--color-text-subtle);

  &:hover {
    background-color: transparent;
    color: var(---color-text-button-secondary-hover);
    border-color: var(--color-border-button-secondary-hover);
  }

  &:active {
    background-color: transparent;
    color: var(---color-text-button-secondary-pressed);
    border-color: var(--color-border-button-secondary-pressed);
  }
}

.negativePill,
.positivePill,
.purple600Pill,
.green500Pill,
.support02Pill,
.support04Pill,
.support05Pill,
.darkBlue500Pill,
.gray600Pill,
.red500Pill {
  padding: 0px 8px;
  color: var(--white-000);
  font-weight: 600;
  font-size: var(--size-xs);
  border-radius: 4px;
  white-space: nowrap;
  width: fit-content;
  display: block;
}

.negativePill {
  background-color: var(--yellow-300);
  color: var(--color-text-accent-yellow);
}

.positivePill {
  background-color: var(--green-300);
  color: var(--color-text-accent-green);
}

.purple600Pill {
  background-color: var(--purple-600);
}

.green500Pill {
  background-color: var(--green-500);
}

.support02Pill {
  background-color: var(--support-02);
}

.support04Pill {
  background-color: var(--blue-300);
  color: var(--blue-700);
}

.support05Pill {
  background-color: var(--support-05);
}

.darkBlue500Pill {
  background-color: var(--dark-blue-500);
}

.gray600Pill {
  background-color: var(--gray-600);
}

.red500Pill {
  background-color: var(--red-500);
}

.card-content {
  background: var(--white-000);
  border-radius: 6px;

  &:not(.sem-padding) {
    padding: 32px;
  }
}

.container-btn {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
}

.form-control {
  background-color: transparent;
  border: none;
  border: solid 1px var(--color-border-control);
  border-radius: 8px;
  padding: 0.5em 1em;
  color: var(--color-text-control);
  height: 40px;
  font-size: 14px;

  &.invalid-payload-input {
    border: solid 1px var(--red-300) !important;
  }

  &:focus {
    background-color: transparent;
    border: solid 1px #137bc7;
    box-shadow: 0px 0px 4px 0px #0f6ebf;
    border-radius: 4px;
    color: var(--gray-900);
    box-sizing: border-box !important;
  }

  &::-moz-placeholder {
    color: var(--color-placeholder-control) !important;
    font-size: 14px;
    font-weight: 400;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: var(--color-placeholder-control) !important;
    font-size: 14px;
    font-weight: 400;
  }

  &::-webkit-input-placeholder {
    color: var(--color-placeholder-control) !important;
    font-size: 14px;
    font-weight: 400;
  }

  &:disabled {
    background-color: var(--gray-100) !important;
    border-color: var(--gray-200) !important;

    &::-moz-placeholder {
      color: var(--gray-600) !important;
    }

    &:-ms-input-placeholder {
      color: var(--gray-600) !important;
    }

    &::-webkit-input-placeholder {
      color: var(--gray-600) !important;
    }
  }
}

.NgxEditor__Wrapper {
  &.invalid-payload-input {
    border: solid 1px var(--red-300) !important;
  }
}

.modal-main-form-field,
.header-filtros-exercicio {
  .form-field-select {
    &.invalid-payload-input:not(.ng-select-focused) {
      .ng-select-container {
        border: 1px solid var(--red-300) !important;
      }
    }

    .ng-select-container {
      border: 1px solid var(--color-border-control) !important;
      background-color: transparent !important;
      color: var(--color-text-control) !important;
      min-height: 40px;
      border-radius: 6px;

      &:hover {
        box-shadow: none;
      }
    }

    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        div {
          .ng-option {
            font-size: 14px;
            font-weight: 400;
          }

          .ng-option-selected {
            background-color: var(--slate-100) !important;

            span {
              font-weight: 400 !important;
            }
          }
        }
      }
    }
  }

  .header-filtros-exercicio-select {
    .ng-select-container {
      height: 32px;
      min-height: 32px !important;
    }
  }

  .ng-select-focused {
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0px 0px 4px 0px #0f6ebf;

    .ng-select-container {
      border: solid 1px #137bc7 !important;
    }
  }

  .ng-select-disabled:not(.readonly) {
    .ng-select-container {
      background-color: var(--gray-100) !important;
      pointer-events: none;
      border-color: var(--gray-200) !important;

      .ng-value-container .ng-placeholder {
        color: var(--gray-600) !important;
      }
    }
  }
}

.header-filtros-exercicio {
  ng-select {
    .ng-select-container {
      border: 1px solid var(--gray-100) !important;
      border-radius: 6px;
      min-height: 32px;
      height: 32px !important;

      .ng-value-container {
        .ng-input {
          input {
            color: var(--gray-800) !important;
          }
        }

        .ng-value-label {
          color: var(--gray-800) !important;
        }
      }
    }
  }
}

.ng-select-opened {
  .ng-select-container {
    border: solid 1px var(--blue-500) !important;
    background-color: transparent !important;
  }
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--color-placeholder-control) !important;
  font-size: 14px;
  font-weight: 400;
  top: unset !important;
}

.ng-select .ng-select-container .ng-value-container {
  margin-left: 6px;
  input {
    margin-left: 6px;
  }

  .ng-value {
    font-size: 14px;
    .ng-value-icon {
      background-color: var(--gray-200) !important;
    }
  }
}

.ng-select-multiple {
  .ng-select-container .ng-value-container {
    .ng-value {
      .ng-value-icon,
      .ng-value-label {
        background-color: var(--gray-200) !important;

        &:first-of-type {
          border-radius: 4px 0px 0px 4px;
        }

        &:last-of-type {
          border-radius: 0px 4px 4px 0px;
        }
      }
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: var(--slate-200);
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  width: 100%;
  height: 300px;
}

@keyframes skeleton-loading {
  0% {
    background-color: #e2e8f00d;
  }
  100% {
    background-color: #cbd5e1;
  }
}

.transparent-btn {
  border: none;
  padding: 1em;
  background-color: transparent;
  color: var(--green-500);
  width: 100%;
}

.g2-btn-search-one {
  @extend .g2-btn-two;
  box-sizing: border-box;
  min-height: 40px;
}

.form-btn-search-container {
  display: flex;
  flex-direction: column;

  label {
    height: 21.6px;
  }

  button {
    width: 100%;
    @extend .g2-btn-search-one;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 14px;

    i {
      font-size: 16px;
    }

    span {
      @media (max-width: 500px) {
        display: block;
      }

      display: none;

      @media (min-width: 950px) {
        display: block;
      }
    }
  }
}

.comma-separated-list > p > span:not(:last-child)::after {
  content: ', ';
}

.required::after {
  content: ' (opcional)';
  color: var(--gray-800);
}

.invalid-payload {
  font-size: 0.7em;
  color: var(--red-500) !important;
}

.detail-page-main-title {
  display: flex;
  align-items: center;
  background-color: var(--slate-200);
  padding: 0.7em 1em 0.7em 1em;
  height: 45px;
  margin-bottom: 1em;
  border-radius: 4px;

  &-left {
    display: flex;
    align-items: center;
    gap: 0.2em;

    i {
      color: var(--brand-color-500);
      font-size: 1.4em;
    }

    span {
      color: var(--gray-900);
      font-weight: 500;
      font-size: 0.95em;
    }
  }
}

.detail-page-main-title-secundario {
  &:not(.sem-indentacao) {
    padding: 0em 0em 0.5rem 1rem;
  }

  &:not(.sem-margin) {
    margin-top: 1.5em;
  }

  &:not(.border-bottom-none) &-conteudo {
    border-bottom: 1px solid var(--gray-100);
    padding-bottom: 0.5em;
  }

  &.sem-indentacao {
    .detail-page-main-title-secundario-conteudo {
      padding-left: 16px;
    }
  }

  span {
    color: var(--gray-900);
    font-weight: 600;
    font-size: var(--text-medium);
  }
}

.bx-spin {
  animation: spin 700ms linear infinite !important;
}

.negrito {
  font-weight: 600;
}

.modal-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 24px;
  background-color: var(--gray-200);
  color: var(--white-000);
  position: relative;
  border-radius: 6px 6px 0px 0px;
  border-bottom: 1px solid var(--gray-400);

  .modal-header-text {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    font-weight: 500;

    i {
      font-size: 20px;
      color: var(--blue-700);
    }

    span {
      font-size: var(--size-md);
      font-weight: 500;
      color: #1c2025;
    }
  }

  button {
    position: absolute;
    right: 32px;
    color: inherit;
    display: flex;
    align-items: center;
    color: var(--blue-700);
    
    .close {
      color: inherit;
    }
  }
}

.modal-footer {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  border: none;
  padding: 38px 24px 24px;

  button {
    min-width: 120px;
  }

  @media (min-width: 500px) {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.botao-toggle {
  background-color: var(--brand-color-500);
  height: 20px;
  display: flex;
  width: 36px;
  align-items: center;
  justify-content: flex-end;
  color: var(--white-000);
  border-radius: 50px;
  padding: 2px;
  cursor: pointer;
  border: none;

  i {
    font-size: 17px;
  }
}

.botao-toggle-off {
  @extend .botao-toggle;
  background-color: var(--slate-300);
  justify-content: flex-start;

  i {
    box-shadow: 0px 10px 15px -3px #0000001a;
  }
}

.scrollbar-tipo-um {
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--slate-100);
    border-radius: 10px;
    border: 3px var(--white-000) solid;
  }
}
